import React from "react";
import {useTranslation} from "react-i18next";
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-white px-2">{t('aboutUs.aboutMe')}</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          {t('aboutUs.knowMeMore')}
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              {t('aboutUs.iAm')}{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                {t('common.myName')}
              </span>
            </h2>
            <p className="text-5 pre-line">
              {t('aboutUs.presentation')}
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat color-dark" data-wow-delay="1.3s">
                  10
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                {t('aboutUs.years')} <span className="fw-700">{t('aboutUs.experiance')}</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">{t('common.name')}:</p>
            <p className="text-4 text-dark fw-600 mb-0">{t('common.myName')}</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">{t('common.age')}:</p>
            <p className="text-4 text-dark fw-600 mb-0">{t('common.myAge')}</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">{t('common.location')}:</p>
            <p className="text-4 text-dark fw-600 mb-0">{t('common.myLocation')}</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">{t('common.email')}:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href={`mailto:${t('common.myEmail')}`}>
                {t('common.myEmail')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
